<template>
  <div class="page-header">
    <div class="left">
      <router-link class="logo" to="/">
        {{ L("台湾青年东莞实习活动") }}
      </router-link>

      <div class="user-name" v-if="resume.email">
        {{ resume.name ? resume.name : resume.email }}
      </div>
      <template v-else>
        <div v-if="!isMobile" class="login-button" @click="setVisible(true)">
          {{ L("登录") }}
        </div>
        <a-icon
          type="user"
          v-else
          class="icon-user"
          @click="setVisible(true)"
        />
      </template>

      <a-dropdown placement="bottomRight" :trigger="['click']">
        <a-icon type="menu-unfold" class="icon-menu" />
        <a-menu slot="overlay">
          <a-menu-item>
            <router-link
              to="/"
              class="menu-item"
              :class="[category == '/' ? 'active' : '']"
              >{{ L("首页") }}
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link
              to="/"
              class="menu-item"
              :class="[category == '/search' ? 'active' : '']"
              >{{ L("职缺") }}
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link
              to="/news"
              class="menu-item"
              :class="[category == '/news' ? 'active' : '']"
              >{{ L("新闻") }}
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link
              to="/video"
              class="menu-item"
              :class="[category == '/video' ? 'active' : '']"
              >{{ L("视频") }}
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link
              to="/activity"
              class="menu-item"
              :class="[category == '/activity' ? 'active' : '']"
              >{{ L("活动") }}
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link
              to="/me"
              class="menu-item"
              :class="[category == '/me' ? 'active' : '']"
              >{{ L("我的") }}
            </router-link>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>

    <register-dialog :visible="visible" @cancel="setVisible(false)" />
  </div>
</template>


<script>
import mobile from "@/mixins/mobile";
import RegisterDialog from "@/components/register-dialog";
import { mapMutations, mapState } from "vuex";
export default {
  mixins: [mobile],
  components: {
    RegisterDialog,
  },

  data() {
    return {
      category: "/",
      keyword: "",
    };
  },

  computed: {
    ...mapState("login", ["visible", "resume"]),
  },

  watch: {
    "$route.path"() {
      this.category = this.$route.path;
    },
  },

  mounted() {
    this.category = this.$route.path;
  },
  methods: {
    ...mapMutations("login", ["setVisible"]),
  },
};
</script>

<style lang="less" scoped>
.page-header {
  background: #004BB0;
  padding: 12px;
  color: #fff;
}

.logo {
  font-size: 20px;
  color: #ffffff;
  font-style: italic;
  font-weight: bold;
}

.user-name {
  width: 6em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: auto;
  font-weight: bold;
  margin-right: 8px;
}

.login-button {
  width: 120px;
  height: 44px;
  background-color: rgba(128, 225, 225, 0.8);
  box-shadow: 0px 10px 15px rgba(36, 84, 228, 0.25);
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon-user {
  font-size: 18px;
  margin-left: auto;
  margin-right: 8px;
}

.icon-menu {
  font-size: 18px;
}

.menu-item {
  padding: 6px 24px;
}
.active {
  color: #05d8aa;
}
</style>