<template>
  <div class="page-header">
    <router-link class="logo" to="/">
      {{ L("台湾青年东莞实习活动") }}
    </router-link>

    <div class="menu">
      <router-link to="/" :class="[category == '/' ? 'active' : '']"
        >{{ L("首页") }}
      </router-link>
      <router-link to="/search" :class="[category == '/search' ? 'active' : '']"
        >{{ L("职缺") }}
      </router-link>
      <router-link to="/news" :class="[category == '/news' ? 'active' : '']"
        >{{ L("新闻") }}
      </router-link>
      <router-link to="/video" :class="[category == '/video' ? 'active' : '']"
        >{{ L("视频") }}
      </router-link>
      <router-link
        to="/activity"
        :class="[category == '/activity' ? 'active' : '']"
        >{{ L("活动") }}
      </router-link>
      <router-link to="/me" :class="[category == '/me' ? 'active' : '']"
        >{{ L("我的") }}
      </router-link>
    </div>

    <div class="search">
      <input
        type="text"
        :placeholder="L('请输入职位、公司')"
        v-model="keyword"
        @keydown.enter="search"
      />
      <div class="search-icon" @click="search">
        <i class="iconfont icon-search" />
      </div>
    </div>

    <div v-if="resume.email">
      {{ resume.name ? resume.name : resume.email }}
    </div>
    <div v-else class="login-button" @click="setVisible(true)">
      {{ L("登录") }}
    </div>

    <register-dialog :visible="visible" @cancel="setVisible(false)" />
  </div>
</template>


<script>
import RegisterDialog from "@/components/register-dialog";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    RegisterDialog,
  },

  data() {
    return {
      category: "/",
      keyword: "",
    };
  },

  computed: {
    ...mapState("login", ["visible", "resume"]),
  },

  watch: {
    "$route.path"() {
      this.category = this.$route.path;
    },
  },

  mounted() {
    this.category = this.$route.path;
  },
  methods: {
    ...mapMutations("login", ["setVisible"]),

    search() {
      if (this.$route.path !== "/search") {
        this.$router.push("/search?keyword=" + this.keyword);
      } else {
        this.$router.replace("/search?keyword=" + this.keyword);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page-header {
  background: #004bb0;
  height: 90px;
  display: flex;
  align-items: center;
  padding: 0 120px;
  color: #fff;
}
.logo {
  font-size: 32px;
  color: #ffffff;
  font-style: italic;
  font-weight: bold;
  margin-right: 20px;
}

.menu {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.menu a {
  color: #fff;
  margin-right: 30px;
  font-size: 20px;
  position: relative;
}
.menu a.active {
  color: #05d8aa;
}
.menu a.active::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -12px;
  height: 2px;
  background: #05d8aa;
}

.search {
  height: 44px;
  border: 1px solid #ffffff;
  box-shadow: 0px 10px 15px rgba(36, 84, 228, 0.25);
  margin-right: 35px;
  display: flex;
  border-radius: 22px;
}
.search input {
  width: 180px;
  box-sizing: border-box;
  border-width: 0;
  outline: none;
  background-color: transparent;
  height: 44px;
  line-height: 44px;
  font-size: 18px;
  color: #ffffff;

  padding-right: 12px;
  padding-left: 24px;
}
.search input::placeholder {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
.search-icon {
  margin-right: 24px;
  cursor: pointer;
}
.search .iconfont {
  font-size: 24px;
  line-height: 44px;
  color: #fff;
}
.login-button {
  width: 120px;
  height: 44px;
  background-color: rgba(128, 225, 225, 0.8);
  box-shadow: 0px 10px 15px rgba(36, 84, 228, 0.25);
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (max-width: 1500px) {
  .logo {
    font-size: 24px;
  }
  .menu a {
    color: #fff;
    margin-right: 20px;
    font-size: 18px;
    position: relative;
  }

  .search {
    height: 40px;
    border: 1px solid #ffffff;
    box-shadow: 0px 10px 15px rgba(36, 84, 228, 0.25);
    margin-right: 30px;
    display: flex;
    border-radius: 22px;
  }
  .search input {
    width: 160px;
    box-sizing: border-box;
    border-width: 0;
    outline: none;
    background-color: transparent;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #ffffff;

    padding-right: 12px;
    padding-left: 24px;
  }
  .search input::placeholder {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
  }
  .search-icon {
    margin-right: 20px;
    cursor: pointer;
  }
  .search .iconfont {
    font-size: 20px;
    line-height: 40px;
    color: #fff;
  }
  .login-button {
    width: 100px;
    height: 40px;
    background-color: rgba(128, 225, 225, 0.8);
    box-shadow: 0px 10px 15px rgba(36, 84, 228, 0.25);
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>